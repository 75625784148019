import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueGtag from 'vue-gtag'

if (process.env.NODE_ENV === 'production') {
    console.log = () => {}; // Supprime tous les logs en prod
    console.debug = () => {}; // Supprime les debugs aussi
    console.info = () => {}; // Optionnel, si tu veux aussi cacher les info
}

createApp(App).use(router).use(VueGtag,{config:{id:'G-SJ8MZ4BFV4'}}).mount('#app')
