<template>
  <div>
    <router-link to="/">
      <button class="gold-button">Accueil</button>
    </router-link>
    <h1>Liste inscrits</h1>
    <button class="gold-button" @click="exportToExcel">Exporter les joueurs en Excel</button>
    <ul>
      <li>
        <h3>Samedi</h3>
        <ul>
          <li v-for="table in groupTableSaturday" :key="table.id">
            <a class="ahovergold" :href="'#table-' + table.id">{{ table.name }} - {{ table.startHour }} - {{
      table.placesNumber }} places restantes</a>
          </li>
        </ul>
      </li>
      <li>
        <h3>Dimanche</h3>
        <ul>
          <li v-for="table in groupTableSunday" :key="table.id">
            <a class="ahovergold" :href="'#table-' + table.id">{{ table.name }} - {{ table.startHour }} - {{
      table.placesNumber }} places restantes</a>
          </li>
        </ul>
      </li>
    </ul>
    <div>
      <h3>Recherche de joueur</h3>
      <div class="search-container">
        <input type="text" v-model="searchQuery" placeholder="Nom, prénom ou numéro de licence" class="search-bar"
          @input="filterPlayers" @focus="showDropdown = true" @blur="hideDropdown" />

        <ul v-if="showDropdown && filteredPlayers.length" class="dropdown">
          <li v-for="player in filteredPlayers" :key="player.id" @mousedown.prevent="selectPlayer(player)">
            {{ player.licence }} - {{ player.name }} {{ player.surname }} - {{ player.rank }}
          </li>
        </ul>
      </div>
    </div>
    <div>
      <h2>Samedi</h2>
      <div class="table-container">
        <table v-for="table in groupTableSaturday" :id="'table-' + table.id">
          <caption>
            {{
      table.name
    }}
            -
            {{
        table.startHour
      }}
          </caption>
          <tr>
            <th>Joueur</th>
            <th>Club</th>
            <th>Point</th>
            <th>Email</th>
            <th>Téléphone</th>
          </tr>
          <tr v-for="playerTable in playerTableData">
            <template v-if="playerTable.tableIds
      .split(',')
      .map((id) => parseInt(id))
      .includes(table.id)
      ">
              <td>
                {{ getPlayerName(playerTable.playerId) }}
              </td>
              <td>
                {{ getPlayerClub(playerTable.playerId) }}
              </td>
              <td>
                {{ getPlayerRank(playerTable.playerId) }}
              </td>
              <td>
                {{ getPlayerMail(playerTable.playerId) }}
              </td>
              <td>
                {{ getPlayerPhoneNumber(playerTable.playerId) }}
              </td>
            </template>
          </tr>
        </table>
      </div>
      <h2>Dimanche</h2>
      <div class="table-container">
        <table v-for="table in groupTableSunday" :id="'table-' + table.id">
          <caption>
            {{
      table.name
    }}
            -
            {{
        table.startHour
      }}
          </caption>
          <tr>
            <th>Joueur</th>
            <th>Club</th>
            <th>Point</th>
            <th>Email</th>
            <th>Téléphone</th>
          </tr>
          <tr v-for="playerTable in playerTableData">
            <template v-if="playerTable.tableIds
      .split(',')
      .map((id) => parseInt(id))
      .includes(table.id)
      ">
              <td>
                {{ getPlayerName(playerTable.playerId) }}
              </td>
              <td>
                {{ getPlayerClub(playerTable.playerId) }}
              </td>
              <td>
                {{ getPlayerRank(playerTable.playerId) }}
              </td>
              <td>
                {{ getPlayerMail(playerTable.playerId) }}
              </td>
              <td>
                {{ getPlayerPhoneNumber(playerTable.playerId) }}
              </td>
            </template>
          </tr>
        </table>
      </div>
      <button class="up-button" v-show="upButton" @click="goUp">
        <img src="../../../assets/arrowUp.png">
      </button>
    </div>
  </div>
  <div v-if="showPopup" class="popup-overlay">
    <div class="popup">
      <h3>Détails du joueur</h3>
      <p><strong>Nom :</strong> {{ selectedPlayerData.name }}</p>
      <p><strong>Prénom :</strong> {{ selectedPlayerData.surname }}</p>
      <p><strong>Classement :</strong> {{ selectedPlayerData.rank }}</p>
      <p><strong>Numéro de licence :</strong> {{ selectedPlayerData.licence }}</p>
      <h4>Tableaux du Samedi</h4>
      <ul>
        <li v-for="table in selectedPlayerData.saturdayTables" :key="table">
          {{ table }}
          <img v-if="table === 'Aucun tableau'" class="bin" src="../../../assets/addPlayer.png"
            @click="addPlayerToTable(table.includes('Samedi') ? 'D' : 'S')">
          <img v-if="table !== 'Aucun tableau'" class="bin" src="../../../assets/bin.png"
            @click="deletePlayerTable(selectedPlayerData.id, table)">
        </li>
      </ul>

      <h4>Tableaux du Dimanche</h4>
      <ul>
        <li v-for="table in selectedPlayerData.sundayTables" :key="table">
          {{ table }}
          <img v-if="table === 'Aucun tableau'" class="bin" src="../../../assets/addPlayer.png"
            @click="addPlayerToTable(table.includes('Dimanche') ? 'S' : 'D')">

          <img v-if="table !== 'Aucun tableau'" class="bin" src="../../../assets/bin.png"
            @click="deletePlayerTable(selectedPlayerData.id, table)">
        </li>
      </ul>

      <button class="close-button" @click="closePopup">Fermer</button>
    </div>
  </div>
  <div v-if="showAddTablePopup" class="popup-overlay">
    <div class="popup">
      <h3>Ajouter le joueur à un tableau</h3>
      <p><strong>Nom :</strong> {{ selectedPlayerData.name }}</p>
      <p><strong>Prénom :</strong> {{ selectedPlayerData.surname }}</p>

      <h4>Tableaux disponibles</h4>
      <ul>
        <li v-for="table in availableTables" :key="table.id">
          {{ table.name }} - {{ table.placesNumber }} places restantes
          <button class="gold-button" @click="addPlayerTable(table.id)">Ajouter</button>
        </li>
      </ul>

      <button class="close-button" @click="closeAddTablePopup">Fermer</button>
    </div>
  </div>
</template>

<script src="./adminTournament.ts"></script>
<style src="./adminTournament.css"></style>
