import axios from 'axios';
import { defineComponent } from "vue";
import { Table } from '@/interfaces/tableInterface';
import { Player } from '@/interfaces/playerInterface';
import { PlayerTable } from '@/interfaces/playerTableInterface';
import { Admin } from '@/interfaces/adminInterface';
import { useRouter } from 'vue-router';

const apiUrl = process.env.VUE_APP_API_BASE_URL; // When testing on mobile

export default defineComponent({
    data() {
        return {
            players: [] as Player[],
            tables: [] as Table[],
            groupTableSaturday: [] as Table[],
            groupTableSunday: [] as Table[],
            playerTableData: [] as PlayerTable[],
            playerTableToCheck: {
                playerId: 0,
                tableId: 0
            },
            admin: [] as Admin[],
            adminIsOk: false,
            upButton: false,
            searchQuery: "",
            showDropdown: false,
            showPopup: false,
            selectedPlayerData: {
                id: 0 as number | undefined,
                name: "",
                surname: "",
                licence: "",
                rank: 0,
                sex: "",
                saturdayTables: [] as string[],
                sundayTables: [] as string[]
            },
            showAddTablePopup: false,
            availableTables: [] as Table[],
        };
    },
    computed: {
        filteredPlayers(): Player[] {
            if (!this.searchQuery) return [];
            const query = this.searchQuery.toLowerCase();

            return (this as any).players
                .filter((player: Player) =>
                    player.name.toLowerCase().includes(query) ||
                    player.surname.toLowerCase().includes(query) ||
                    player.licence.toString().includes(query)
                )
                .slice(0, 10); // Limite à 10 résultats
        }
    },
    created() {
        this.admin = JSON.parse(localStorage.getItem('admin') || 'null');
        if (this.admin != null) {
            this.adminIsOk = true;
            this.getAllPlayers();
            this.getAllTables();
            this.getAllPlayerTable();
        }
        else {
            const router = useRouter();
            router.push('/admin-login');
        }
    },
    methods: {
        async getAllPlayers() {
            try {
                const response = await axios.get(`${apiUrl}/getAllPlayers`);
                this.players = response.data;
            } catch (error) {
                console.error('Erreur lors de la récupération des joueurs :', error);
            }
        },
        getPlayerName(playerId: number) {
            const player = this.players.find(player => player.id === playerId);
            return player ? `${player.name} ${player.surname}` : '';
        },
        getPlayerClub(playerId: number) {
            const player = this.players.find(player => player.id === playerId);
            return player ? player.club : '';
        },
        getPlayerRank(playerId: number) {
            const player = this.players.find(player => player.id === playerId);
            return player ? player.rank : '';
        },
        getPlayerMail(playerId: number) {
            const player = this.players.find(player => player.id === playerId);
            return player ? player.mail : '';
        },
        getPlayerPhoneNumber(playerId: number) {
            const player = this.players.find(player => player.id === playerId);
            return player ? player.phone : '';
        },
        async getAllPlayerTable() {
            try {
                this.playerTableData = [];
                const response = await axios.get<PlayerTable[]>(`${apiUrl}/getAllPlayerTable`);
                this.playerTableData = response.data;
                this.sortPlayersByRank();
            } catch (error) {
                console.error('Erreur lors de la récupération des tableaux et des joueurs :', error);
            }
        },
        async deletePlayer(playerId: any) {
            try {
                await axios.delete(`${apiUrl}/deletePlayer/${playerId}`);
            } catch (error) {
                console.error("Erreur lors de la suppression du joueur :", error);
            }
        },
        async getAllTables() {
            try {
                this.tables = [];
                this.groupTableSaturday = [];
                this.groupTableSunday = [];
                const response = await axios.get<Table[]>(`${apiUrl}/getAllTables`);
                this.tables = response.data;
                this.tables.forEach(table => {
                    if (table.day == 'S') {
                        this.groupTableSaturday.push(table);
                    }
                    if (table.day == 'D') {
                        this.groupTableSunday.push(table);
                    }
                });
            } catch (error) {
                console.error('Erreur lors de la récupération des tableaux :', error);
            }
        },
        async deletePlayerTable(playerId: number, tableName: string) {
            try {
                const table = this.tables.find(table => table.name === tableName);
                const tableId = table?.id;
                await axios.delete(`${apiUrl}/deletePlayerTable/`, {
                    params: {
                        playerId: playerId,
                        tableId: tableId
                    }
                });
                const playerEmail = this.getPlayerMail(playerId);
                await this.sendUnsuscribeMail(playerEmail, tableName);
                if (tableId != undefined) {
                    await this.updatePlacesNumber(tableId);
                }
                if (tableId != undefined) {
                    this.playerTableToCheck = {
                        playerId: playerId,
                        tableId: tableId
                    }
                }
                const index = this.playerTableData.indexOf(this.playerTableToCheck);
                if (index !== -1) {
                    this.playerTableData.splice(index, 1);
                }
                await this.closePopup();
                // Ajouter un délai d'attente de 1 seconde (ou le nombre de millisecondes souhaité)
                await this.getAllPlayerTable();

                const playerExists = this.playerTableData.some(pt => pt.playerId === playerId);
                
                if (!playerExists) {
                    await this.deletePlayer(playerId);
                }
                
                // Rafraîchir les tables et les joueurs après suppression éventuelle du joueur
                await this.getAllPlayers();
                await this.getAllTables();
                
            } catch (error) {
                console.error("Erreur lors de la suppression de la ligne PlayerTable :", error);
            }
        },
        async updatePlacesNumber(tableIdToIncreasePlaces: number) {
            try {
                await axios.put(`${apiUrl}/increaseTablePlaces/${tableIdToIncreasePlaces}`);
            } catch (error) {
                console.error(error);
            }
        },
        async updateDecreasePlacesNumber(tableIdToReducePlaces: number) {
            try {
                await axios.put(`${apiUrl}/decreaseTablePlaces/${tableIdToReducePlaces}`);
            } catch (error) {
                console.error(error);
            }
        },
        sortPlayersByRank() {
            this.playerTableData.sort((a, b) => {
                const playerARank = this.getPlayerRank(a.playerId);
                const playerBRank = this.getPlayerRank(b.playerId);

                if (typeof playerARank === 'number' && typeof playerBRank === 'number') {
                    return playerBRank - playerARank;
                }

                if (typeof playerARank === 'number') {
                    return -1;
                }

                if (typeof playerBRank === 'number') {
                    return 1;
                }

                // Les rank sont comparé en tant que string
                return (playerBRank as string).localeCompare(playerARank as string);
            });
        },
        async sendUnsuscribeMail(receiverMail: string, tableForMessage: any) {
            try {
                const tableHtml = `<li>${tableForMessage}</li>`;
                const response = await axios.post(`${apiUrl}/sendUnsuscribeEmail`, { receiverMail, tableHtml });
                console.log('E-mail envoyé avec succès :', response.data);
            } catch (error) {
                console.error('Erreur lors de l\'envoi de l\'e-mail :', error);
            }
        },
        // Fonction pour exporter les données en Excel
        async exportToExcel() {
            try {
                // Appel de l'API pour obtenir le fichier Excel
                const response = await axios.get(`${apiUrl}/export-to-excel`, {
                    responseType: 'blob',
                });

                // Créer un lien pour télécharger le fichier
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = 'Joueurs_Tournoi.xlsx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Erreur lors de l\'exportation :', error);
            }
        },
        handleScroll() {
            // Rendre le bouton visible si l'utilisateur défile de plus de 300px
            this.upButton = window.scrollY > 300;
        },
        goUp() {
            // Remonter doucement en haut de la page
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        selectPlayer(player: Player) {
            this.searchQuery = `${player.name} ${player.surname}`;
            this.showDropdown = false;
            this.openPopup(player);
        },
        hideDropdown() {
            setTimeout(() => {
                this.showDropdown = false;
            }, 200);
        },
        // Méthode pour afficher la popup
        async openPopup(player: Player) {
            try {
                // Appel API pour récupérer les tableaux du joueur
                const response = await axios.get<PlayerTable[]>(`${apiUrl}/getTableByPlayer`, {
                    params: { playerId: player.id }
                });

                console.log(`Tableaux récupérés pour ${player.name} ${player.surname} :`, response.data);

                // Extraction des tableIds récupérés
                const tableIds = response.data.map(pt => pt.tableId);

                // Filtrer les tableaux correspondants au joueur
                let saturdayTables = this.groupTableSaturday
                    .filter(table => table.id !== undefined && tableIds.includes(table.id))
                    .map(table => table.name);

                let sundayTables = this.groupTableSunday
                    .filter(table => table.id !== undefined && tableIds.includes(table.id))
                    .map(table => table.name);

                // Assurer qu'il y ait toujours 2 lignes :
                // - Si 0 tableau => ["Aucun tableau", "Aucun tableau"]
                // - Si 1 tableau => [Tableau, "Aucun tableau"]
                // - Si 2+ tableaux => [Tableau 1, Tableau 2]
                saturdayTables = [...saturdayTables, "Aucun tableau", "Aucun tableau"].slice(0, 2);
                sundayTables = [...sundayTables, "Aucun tableau", "Aucun tableau"].slice(0, 2);

                // Mettre à jour les données du joueur sélectionné
                this.selectedPlayerData = {
                    id: player.id,
                    name: player.name,
                    surname: player.surname,
                    licence: player.licence.toString(),
                    rank: player.rank,
                    sex: player.sex,
                    saturdayTables,
                    sundayTables
                };

                this.showPopup = true; // Ouvrir la popup après la récupération des données
            } catch (error) {
                console.error("Erreur lors de la récupération des tableaux du joueur :", error);
            }
        },
        closePopup() {
            // Ferme la popup
            this.showPopup = false;

            // Vide la barre de recherche
            this.searchQuery = '';

            // Optionnel : Cache aussi les résultats de la recherche (si tu utilises un filtre)
            this.filteredPlayers = [];
        },
        async addPlayerToTable(dayType: 'S' | 'D') {
            try {
                // Récupérer les tableaux du jour sélectionné (samedi ou dimanche)
                const allTables = dayType === 'S' ? this.groupTableSaturday : this.groupTableSunday;
    
                // Filtrer les tableaux disponibles selon les critères demandés
                this.availableTables = allTables.filter(table => 
                    table.placesNumber > 0 &&
                    this.selectedPlayerData.rank < table.maxRank &&
                    !this.playerTableData.some(pt => pt.playerId === this.selectedPlayerData.id && pt.tableId === table.id) &&
                    !(table.sex === 'F' && this.selectedPlayerData.sex !== 'F')

                );
    
                // Afficher la popup
                this.showAddTablePopup = true;
            } catch (error) {
                console.error("Erreur lors de la récupération des tableaux disponibles :", error);
            }
        },
        async addPlayerTable(tableId: number) {
            try {
                const data = {
                    playerId: this.selectedPlayerData.id,
                    tableId: tableId
                }

                const response = await axios.post(`${apiUrl}/addPlayerTable`, JSON.stringify(data), {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                console.log(response.data);
                 // Rafraîchir les données
                 await this.updateDecreasePlacesNumber(tableId);
                 await this.getAllPlayerTable();
                 await this.getAllTables();
     
                 await this.closeAddTablePopup();
                 await this.closePopup();
            } catch (error) {
                console.error(error);
            }
        },
        closeAddTablePopup() {
            this.showAddTablePopup = false;
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
});
